import React from "react";
import {
  heading150,
  heading300,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";

import Hubspot from "./assets/hubspot.inline.svg";

const heading = `Next Steps`;
const title = `Book a demo`;
const description = `Come along to one of our informal virtual technology demonstrations with our team of materials scientists. You will hear a bit more about our work here at Plastometrex before seeing The PLX-Benchtop conduct a live test. Feel free to invite your colleagues along too.`;
const buttonText = `Book a demo`;

const BookADemo = () => {
  return (
    <Wrapper>
      <Container>
        <VideoWrapper>
          <Video
            src="https://player.vimeo.com/video/584822198?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0"
            frameBorder="0"
            width="100%"
            height="100%"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></Video>
        </VideoWrapper>
        <TextContent>
          <Heading>{heading}</Heading>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ButtonWrapper
            href="https://meetings.hubspot.com/m-coto"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button tier="primary" size="large" arrow fullWidth>
              {buttonText}
            </Button>
          </ButtonWrapper>
          <LogoWrapper>
            <Hubspot />
          </LogoWrapper>
        </TextContent>
      </Container>
    </Wrapper>
  );
};

export default BookADemo;

const Wrapper = styled("div", {
  width: "100%",
  margin: "auto",
  paddingBottom: 50,
  "@md": {
    width: "90%",
    maxWidth: 1087,
  },
});

const Container = styled("div", {
  "@md": {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
});
const VideoWrapper = styled("div", {
  padding: "56.25% 0 0 0",
  position: "relative",
  display: "block",
  "@md": {
    flex: "1 0 50%",
    borderRadius: 4,
    overflow: "hidden",
  },
});
const TextContent = styled("div", {
  width: "90%",
  margin: "auto",
  "@md": {
    marginRight: 50,
    maxWidth: 431,
  },
});
const Heading = styled("h6", {
  ...paragraphTextSmall,
  textTransform: "uppercase",
  color: "$blue100",
  marginBottom: 0,
  "@md": {
    display: "block",
  },
});
const Title = styled("h4", {
  ...heading300,
  marginBottom: 10,
  marginTop: 0,
});
const Description = styled("div", {
  ...paragraphTextSmall,
  marginTop: 20,
  marginBottom: 32,
});
const ButtonWrapper = styled("a", {});
const LogoWrapper = styled("div", {
  marginTop: 10,
});

const Video = styled("iframe", {
  position: "absolute",
  top: 0,
  left: 0,
  "@md": {
    borderRadius: 4,
    overflow: "hidden",
  },
});
