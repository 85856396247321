import React from "react";
import BookADemo from "../components/bookDemo/BookADemo";
import Layout from "../components/Layout";

const seo = {
  title: "Plastometex Demo",
  description:
    "Book an online virtual demonstration to learn more about the PLX-Benchtop - a mechanical testing machine that generates stress/strain curves in 3 minutes",
};

const BookADemoPage = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <BookADemo />
    </Layout>
  );
};

export default BookADemoPage;
